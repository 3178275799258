.cookieContainerFixed {
    position: fixed;
    z-index: 9990;
    width: 100%;
    backdrop-filter: blur(4px);
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    bottom: 0;
}

.cookieContainer {
    position: absolute;
    display: flex;
    height: 50%;
    background: red;
    width: 100%;
    z-index: 9990;
    bottom: 0;
}

.cookieContainerRel {
    position: relative;
    display: flex;
    background: white;
    flex-direction: column;
    width: 100%;
}

.cookieHeaderContainer {
    display: block;
    padding: 13px 16px;
    background: white;
    z-index: 8;
}

.cookieHeaderContainer img {
    height: 32px;
    width: 32px;
    border-radius: 4px;

}

.cookieHeaderContainerShadow {
    box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.2),
    0 3px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 9px 0 rgba(0, 0, 0, 0.12);
}


.cookieContentContainer {
    display: block;
    padding: 0 16px;
    background: white;
    overflow: auto;
}

.cookieButtonContainer {
    display: flex;
    justify-content: center;
    padding: 13px 16px;
    background: white;
    z-index: 9;
    box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.2),
    0 3px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 9px 0 rgba(0, 0, 0, 0.12);
}