
.tinySlider {

}

.tns-outer, .tns-ovh, .tns-inner, .tinySlider {
    position: relative;
    height: 100%;
}

.tns-nav {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 15px;
    z-index: 1;
    left: 0;
    right: 0;
}

.tns-controls {
    position: absolute;
    display: flex;
    width: 100%;
    top: 0;
    bottom: 60px;
}

.tns-controls button {
    padding: 16px;
    top: 46%;
    z-index: 1;
    color: white;
    font-weight: bold;
    border: none;
    background-color: transparent;
    outline: none;
    position: absolute;
}

[data-controls="prev"] {
    left: 0;
}

[data-controls="next"] {
    right: 0;
}

.tns-controls button:focus {
    outline: none;
}

.tns-nav .tns-nav-active {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background: #FFFFFFE6;
    border-radius: 50%;
    border: 0;
}

.tns-nav button {
    width: 11px;
    margin: 0 5px;
    height: 11px;
    background: #00000033;
    border-radius: 50%;
    border: 0;
}

.tns-nav button:focus {
    outline: none;
}

.socialLinks * {
    box-sizing: content-box !important;
}
