.blog h2 {
    padding: 0;
    margin: 0;
}

.blog ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.blog li {
    margin: 0 16px 20px 16px;
}

.blog .fakeLi {
    text-decoration: none;
    display: flex;
    padding: 10px;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 -1px 0 0 rgba(0, 0, 0, 0.12),
    1px 0 0 0 rgba(0, 0, 0, 0.12),
    -1px 0 0 0 rgba(0, 0, 0, 0.12);
}

.postItem .wrapPostItem {
    display: flex;
    width: 100%;
}

.postItem .wrapText {
    width: 85%;
    margin-right: 8px;
    position: relative;
    white-space: pre-wrap;
}

.postItem img {
    width: 15vw;
    height: 15vw;
    border-radius: 4px;
    object-fit: cover;
}

.postItem {
    background: #FFFFFF;
}

.postItem a {
    color: black;
    text-decoration: none;
}

.postItem a:hover {
    color: black;
    text-decoration: none;
}

.postItem:hover {
    background-color: #DDDDDD;
}

.postRowBottom {
    position: absolute;
    bottom: 0;
    float: bottom;
    align-items: center;
    width: 100%;
    color: rgba(0, 0, 0, 0.64);
    font-size: 2.5vw;
}

.postRowLikeCount {
    display: flex;
    align-items: center;
    float: left;
}

.postRowLikeCount p {
    margin: 0;
    padding-left: 4px;
    font-size: 2.5vw;
}

.postRowLikeCount button {
    min-width: 0;
    padding: 2px 4px;
}

.postDate {
    text-align: right;
    float: right;
    color: rgba(0, 0, 0, 0.64);
}

.postAuthor {
    text-align: right;
}

.postTitle {
    margin: 0;
    text-align: start;
    font-weight: 700;
    font-size: 4vw;
    color: rgba(0, 0, 0, 0.84);
}

.postSummary {
    margin: 0;
    text-align: start;
    font-size: 2.5vw;
    color: rgba(0, 0, 0, 0.64);
}

.postRowLikeIcon {
    width: 3vw;
    height: 3vw;
}

.postRowCommentIcon {
    width: 3vw;
    height: 3vw;
    transform: scaleX(-1);
}

@media screen and (max-width: 700px) {
    .blog {
        width: 100%;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 600px) {
    .postItem img {
        width: 125px;
        height: 125px;
    }
}

@media screen and (min-width: 700px) {
    .blog {
        width: 100%;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }

    .postTitle {
        font-weight: 700;
        font-size: 1.5em;
    }

    .postRowLikeIcon {
        width: 1.25em;
        height: 1.25em;
    }

    .postRowCommentIcon {
        width: 1.25em;
        height: 1.25em;
    }

    .postRowBottom, .postRowLikeCount p {
        font-size: 1em;
    }

    .postSummary {
        font-size: 1em;
    }
}

@media screen and (min-width: 850px) {
    .blog {
        width: 850px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }
}