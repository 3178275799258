.postCommentLikeCount {
    display: flex;
    align-items: center;
}

.postCommentLikeCount button {
    min-width: 0;
    padding: 2px 4px;
}

.postCommentLikeCount p {
    margin: 0;
    padding-left: 4px;
}