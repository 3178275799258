.home-view-2 {
    background: url('../../res/drawable/bg/green_bg.svg') no-repeat left bottom;
    background-size: cover;
}

.homeContent2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.homeText2 {
    font-size: 2em;
}

.homeSubtext2 {
    font-size: 1em;
    margin-bottom: 4em;
}

@media screen and (min-width: 700px) {
    .homeText2 {
        font-size: 4em;
    }

    .homeSubtext2 {
        font-size: 2em;
    }
}