.home-view-3 {
    background: url('../../res/drawable/bg/purple_bg.svg') no-repeat left bottom;
    background-size: cover;
}

.homeContent3 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.homeText3 {
    font-size: 2em;
}

.homeSubtext3 {
    font-size: 1em;
    margin-bottom: 4em;
}

@media screen and (min-width: 700px) {
    .homeText3 {
        font-size: 4em;
    }

    .homeSubtext3 {
        font-size: 2em;
    }
}