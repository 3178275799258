.quoteList h2 {
    padding: 0;
    margin: 0;
}

.quoteList ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.quoteList li {
    padding: 10px;
    margin: 0 16px 20px 16px;
    border-radius: 8px;
    box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    -2px 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 -1px 0 0 rgba(0, 0, 0, 0.12),
    1px 0 0 0 rgba(0, 0, 0, 0.12),
    -1px 0 0 0 rgba(0, 0, 0, 0.12);
}

.quote {
    background: #FFFFFF;
    display: grid;
    width: auto;
}

.quote a {
    color: black;
    text-decoration: none;
}

.quote:hover {
    background-color: #DDDDDD;
}

.quoteBody {
    color: rgba(0, 0, 0, 0.84);
}

.quoteAuthor {
    text-align: right;
    float: right;
    color: rgba(0, 0, 0, 0.64);
}

.quoteRowBottom {
    bottom: 0;
    float: bottom;
    align-items: center;
    width: 100%;
    color: rgba(0, 0, 0, 0.64);
    font-size: 2.5vw;
}

@media screen and (max-width: 700px) {
    .quoteList {
        width: 100%;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 700px) {
    .quoteList {
        width: 100%;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }

    .quoteRowBottom {
        font-size: 1em;
    }
}

@media screen and (min-width: 850px) {
    .quoteList {
        width: 850px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }
}
