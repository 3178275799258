
.App {
    display: contents;
    min-height: 100%;
    height: auto !important;
    width: 100%;
}

.AppContent {
    min-height: 100%;
    height: auto !important;
    width: 100%;
}

*:focus {
    outline: none !important;
}