.app-header {
    width: 100%;
    background-color: #222222;
    height: auto;
    color: white;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
    position: sticky;
    z-index: 10;
    overflow: auto;
    top: 0;
    left: 0;
    right: 0;
}

.box {
    float: left;
}

.navGrid {
    list-style-type: none;
    margin: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 32px 0 32px;
    overflow: hidden;
    background-color: #333333;
}

.app-header .name {
    background-color: #333333;
    padding: 0 32px 0 32px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name a {
    padding: 6px 8px;
    min-width: 64px;
    text-align: start;
    color: white;
    font-weight: 700;
    line-height: 1.5em;
    font-size: 1.75em;
    border-radius: 8px;
    text-decoration: none;
}

.name a:hover {
    border-radius: 8px;
    color: black;
}

@media screen and (max-width: 750px) {
    .box:nth-child(1) {
        width: 100%;
        background-color: #333333;
    }

    .box:nth-child(2) {
        width: 100%;
        background-color: #333333;
    }

    .box:nth-child(3) {
        width: 100%;
        background-color: #333333;
    }
}

@media screen and (min-width: 750px) {
    .box:nth-child(1) {
        width: 30%;
        background-color: #333333;
    }

    .box:nth-child(2) {
        width: 30%;
        background-color: #333333;
    }

    .box:nth-child(3) {
        width: 40%;
        background-color: #333333;
    }

    .app-header .name {
        background-color: #333333;
        height: 75px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .name a {
        color: white;
        font-weight: 700;
        font-size: 1.5em;
        text-decoration: none;
    }

    .navGrid {
        justify-content: flex-end;
        height: 75px;
    }
}

@media screen and (min-width: 850px) {
    .box:nth-child(1) {
        width: 33%;
        background-color: #333333;
    }

    .box:nth-child(2) {
        width: 34%;
        background-color: #333333;
    }

    .box:nth-child(3) {
        width: 33%;
        background-color: #333333;
    }

    .app-header .name {
        background-color: #333333;
        height: 75px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .name a {
        color: white;
        font-weight: 700;
        font-size: 1.75em;
        text-decoration: none;
    }

    .navGrid {
        justify-content: flex-end;
        height: 75px;
    }
}