
.contact {
    width: 100%;
    margin: 32px auto;
}

.contactCard {
    background-color: #FFF;
    padding: 16px;
    margin: 16px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 -1px 0 0 rgba(0, 0, 0, 0.12),
    1px 0 0 0 rgba(0, 0, 0, 0.12),
    -1px 0 0 0 rgba(0, 0, 0, 0.12);
}

.contactCard .title {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 0.5em;
    color: #222;
    font-weight: 700;
}

.contactContainer {
    display: block;
}

.inputWrap {
    padding: 8px;
    position: relative;
}

.contactInput, .contactTextarea {
    padding: 8px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1em;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
}

.contactTextarea {
    resize: none;
}

.contactContainer .button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

    font-size: 1em;
    color: #222;
    font-weight: 600;
    outline: none;
    background-color: #00C853;
    border-color: rgba(105, 105, 105, 1);
    border-width: inherit;
    border-radius: 4px;
    padding: 16px 32px;
    border-style: solid;

    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
}

.msgMaxLength {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 16px;
}

@media screen and (min-width: 700px) {
    .contactCard {
        padding: 32px;
        margin: 32px;
    }

    .contactCard .title {
        font-size: 1.75em;
        margin-bottom: 1em;
    }

    .contactContainer .button {
        margin-top: 16px;
        font-size: 1em;
        padding: 8px 32px;
    }
}

@media screen and (min-width: 850px) {
    .contact {
        width: 850px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }
}