
.home-view-1 {
    background: url('../../res/drawable/bg/light_blue_bg.svg') no-repeat left bottom;
    background-size: cover;
}

.homeText1 {
    font-size: 2em;
    margin-top: 50px;
}

.homeSubtext1 {
    font-size: 1em;
}

.homeSubscribe {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 700px) {
    .homeText1 {
        margin-top: 150px;
        font-size: 3em;
    }

    .homeSubtext1 {
        font-size: 1.75em;
    }

    .homeSubscribe {
        justify-content: flex-end;
        align-items: center;
    }
}

@media screen and (min-width: 850px) {
    .homeSubscribe {
        width: 850px;
    }
}