
.socialLinks ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 10;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.socialLinks li {
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialLinks li a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    text-decoration: none;
}

.socialLinks li a:hover {
    border-radius: 8px;
    color: black;
}

.socialLinks svg {
    height: 35px;
    width: 35px;
}

@media screen and (max-width: 700px) {
    .socialLinks ul {
        list-style-type: none;
        margin: 0;
        z-index: 10;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
}

@media screen and (min-width: 700px) {
    .socialLinks ul {
        list-style-type: none;
        margin: 0;
        z-index: 10;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .socialLinks svg {
        height: 40px;
        width: 40px;
    }
}

@media screen and (min-width: 850px) {
    .socialLinks ul {
        list-style-type: none;
        margin: 0;
        z-index: 10;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .socialLinks svg {
        min-height: 45px;
        min-width: 45px;
    }
}