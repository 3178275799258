
.homeContainer {
    position: absolute;
    width: 100%;
    height: calc(100% - 150px);
    padding: 0;
}

.home {
    position: relative;
    height: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.home-item {
    position: relative;
    display: block;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.homeWrap {
    padding: 16px;
}

.homeText {
    font-family: sans-serif;
    width: 100%;
    color: #eee;
    visibility: hidden;
    font-weight: 800;
    align-self: flex-start;
    text-align: center;
}

.homeSubtext {
    visibility: hidden;
    font-family: sans-serif;
    width: 100%;
    color: #eee;
    font-weight: 600;
    align-self: flex-start;
    text-align: center;
}

.titleAnim {
    visibility: visible;
    animation: zoomIn;
    animation-duration: 3s;
}

.subtitleAnim {
    visibility: visible;
    animation: zoomIn;
    animation-duration: 3s;
}

@media screen and (min-width: 750px) {
    .homeContainer {
        height: calc(100% - 75px);
    }
}
