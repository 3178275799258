.inputWrap label {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

input[type="file"] {
    display: none;
}

.inputFileText {
    float: left;
    margin: 0;
}

.inputFileButton {
    background: #00C853;
    color: #000;
    vertical-align: middle;
    margin: 0;
    padding: 4px 8px;
    border-radius: 4px;
    border: none;
    box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.2);
    float: right;
}