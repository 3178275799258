
.socialLinksFooter ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 10;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.socialLinksFooter li {
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialLinksFooter li a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    text-decoration: none;
}

.socialLinksFooter li a:hover {
    border-radius: 8px;
    color: black;
}

.socialLinksFooter svg {
    height: 35px;
    width: 35px;
}