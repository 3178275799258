.wrap {
    background-color: #FFF;
    display: table;
    table-layout: fixed;
    width: 100%;
}

.banner {
    width: 100%;
}

.post {
    margin: 32px;
}

.postContentImgWrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.postContentImg {
    width: 100%;
}

.postHeader {
    width: 100%;
}

.postHeader .postDate {
    position: unset;
}

.vidContainer {
    width: 50%;
}

.youTubeVid {
    width: 100%;
}

.img2wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.img2 {
    padding: 2px;
    width: 49%;
}

.imgVertical {
    padding: 2px;
    width: 32%;
    align-self: flex-end;
}

.imgHorizontal {
    padding: 2px;
    width: 65%;
    align-self: flex-end;
}

.postLikeCount {
    display: flex;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.postLikeCount p {
    margin: 0;
    padding-left: 4px;
}

.postCommentIcon {
    transform: scaleX(-1);
}

.postLikeIcon, .postCommentIcon {
    width: 1.5em;
    height: 1.5em;
}

@media screen and (max-width: 700px) {
    .postBody {
        width: 100%;
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 700px) {
    .postBody {
        width: 100%;
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 850px) {
    .postBody {
        width: 850px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }
}